import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpModule } from "@angular/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  FirebaseQuery,
  FirebaseStorage,
  FirebaseImage,
  FirebaseAuth,
  OrdinaryComponent,
  OrdinaryAlgorithm,
  OrdinaryQuery,
  OrdinaryAppSetting,
  OrdinaryQueryBusiness,
  QueryVhsales,
  QRBarcodeScannerCamera,
  Driver,
  QueryVhenergy,
  vhPipeModule,
  VhMqttService
} from "vhframeworks";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { File } from "@ionic-native/file/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
//===================Language=============//
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LanguageService } from "./pages/language/language.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { MqttModule, MqttService } from "ngx-mqtt";
import { MQTT_SERVICE_OPTIONS as mqtt_service_option } from 'vhframeworks';
export function mqttServiceFactory() {
 return new MqttService(mqtt_service_option);
}

@NgModule({
  declarations: [AppComponent], 
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpModule,
    IonicModule.forRoot({
      mode: "ios",
      backButtonText: "",
      swipeBackEnabled: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,    
    MqttModule.forRoot(mqtt_service_option)
    // MqttModule.forRoot({
    //   provide: MqttService,
    //   useFactory: mqttServiceFactory
    // })   
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FirebaseStorage,
    FirebaseImage,
    FirebaseQuery,
    FirebaseAuth,
    OrdinaryComponent,
    OrdinaryAlgorithm,
    OrdinaryQuery,
    OrdinaryAppSetting,
    OrdinaryQueryBusiness,
    QueryVhsales,
    QRBarcodeScannerCamera,
    vhPipeModule,
    QueryVhenergy,
    BarcodeScanner,
    InAppPurchase2,
    ImagePicker,
    WebView,
    Camera,
    Driver,
    File,
    SocialSharing,
    ScreenOrientation, 
    VhMqttService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
