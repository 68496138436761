import { Component } from "@angular/core";

import { Platform, NavController } from "@ionic/angular";
//import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { Plugins, StatusBarStyle, StatusBarAnimation } from "@capacitor/core";

import {
  FirebaseStorage,
  FirebaseAuth,
  OrdinaryAppSetting
} from "vhframeworks";

import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
const { StatusBar } = Plugins;
//import { TranslateService } from "@ngx-translate/core";
//import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"]
})
export class AppComponent {
  showSplash = false;
  isStatusBarLight = true;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private navCtrl: NavController,
    private firebaseStorage: FirebaseStorage,
    private firebaseAuth: FirebaseAuth,
    //private statusBar: StatusBar,
    private ordinaryAppSetting: OrdinaryAppSetting,
    private screenOrientation: ScreenOrientation,
    //private translate: TranslateService
  ) {
    //this.translate.setDefaultLang("en");
    this.splashScreen.hide();
    this.initializeApp();
    this.ordinaryAppSetting.syncExistedTheme();
  }

  initializeApp() {
    this.firebaseStorage.initialize("vhenergy");
    this.firebaseAuth.initialize();
    this.tryCheckSignedIn();
    this.platform.ready().then(() => {
     // this.changeStatusBar();
      // if (this.platform.is("ipad")) {
      //   this.screenOrientation.lock(
      //     this.screenOrientation.ORIENTATIONS.LANDSCAPE
      //   );
      // } else if (this.platform.is("tablet")) {
      //   this.screenOrientation.lock(
      //     this.screenOrientation.ORIENTATIONS.LANDSCAPE
      //   );
      // } else {
      //   this.screenOrientation.lock(
      //     this.screenOrientation.ORIENTATIONS.PORTRAIT
      //   );
      // }
      //this.splashScreen.hide();
      //  setTimeout(() => {
      //    this.showSplash = false;
      //  }, 4000);
    });
  }

  tryCheckSignedIn() {
    this.firebaseAuth
      .checkSignedIn()
      .then(resp => {
        this.showSplash = false;
        if (resp) {
          this.navCtrl.navigateRoot("/tabs");
        } else {
          this.navCtrl.navigateRoot("/login");
        }
      })
      .catch(() => { });
  }

  changeStatusBar() {
    StatusBar.getInfo().then(res => console.log(res));
    StatusBar.setBackgroundColor(
      {
        color: "#ffffff"
      })
    //this.statusBar.styleDefault();
    ///this.statusBar.backgroundColorByHexString('#ffffff');
    // StatusBar.setBackgroundColor({ color: "white" });
    StatusBar.setStyle({
      style: StatusBarStyle.Light
    });
    StatusBar.setOverlaysWebView({
      overlay: false
    });
    //this.isStatusBarLight = !this.isStatusBarLight;
  }

  // hideStatusBar() {
  //   StatusBar.hide();
  // }

  // showStatusBar() {
  //   StatusBar.show();
  // }
}
