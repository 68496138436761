import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  /* {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, */
  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then(m => m.TabsPageModule)
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then(m => m.LoginPageModule)
  },
  {
    path: "overviews",
    loadChildren: () =>
      import("./pages/overviews/overviews.module").then(
        m => m.OverviewsPageModule
      )
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/reports/reports.module").then(m => m.ReportsPageModule)
  },
  {
    path: "products",
    loadChildren: () =>
      import("./pages/products/products.module").then(m => m.ProductsPageModule)
  },
  {
    path: "sell",
    loadChildren: () =>
      import("./pages/sell/sell.module").then(m => m.SellPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
